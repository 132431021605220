import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import { BasicSelect, ModelSelect } from 'vue-search-select';
import DatePicker from 'vue2-datepicker';
import FmsSubunit from '../../../../../fms/unitInventory/fmsSubunit';
import vendorDetails from './vendorDetails';
import store from '../../../../../../../store';
import openKmAuthentication from '../openKmAuthentication';
import downlaodFiles from '../assignSubUnitDetails/downloadFiles';
import Consult from '../../../../../onlineApproval/internalWorkFlow/addInternalWorkflow/consult';

export default {
  name: 'AssignSubUnitDetails',
  props: ['parentLeaseUnitData', 'leasePrjUnitId', 'wf_inprocess_dtl_id'],
  components: {
    BasicSelect,
    ModelSelect,
    DatePicker,
    FmsSubunit,
    vendorDetails,
    openKmAuthentication,
    downlaodFiles,
    Consult
  },
  watch: {
    fmsFloor: function() {
      this.lovFieldName = appStrings.FMSLOV.FMSFLOOR;
      this.lovId = this.fmsFloor.value;
      this.getOtherLovByProjectId();
    },
    searchFmsSubUnitName: function() {
      if (this.searchFmsSubUnitName && this.searchFmsSubUnitName.length > 0) {
        this.fmsSubUnitData = this.filteredEventList();
      } else {
        this.fmsSubUnitData = this.unfilteredSubUnitList;
      }
    },
    selectAllCheckBox: function() {
      this.checkUncheckAll();
    },
    downloadCurrentPage: function() {
      this.getLeaseUnitMappingDetailsById();
    },
    downloadPerPage: function() {
      this.downloadCurrentPage = 1;
      this.getLeaseUnitMappingDetailsById();
    }
  },
  data() {
    return {
      unsubscribe: null,
      loader: false,
      isSuccess: false,
      selectAllCheckBox: false,
      showAlert: false,
      isApprovedByCurrrentUser: false,
      showConsultModal: false,
      notificationSummary: null,
      responseMsg: [],
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      downloadCurrentPage: 1,
      downloadPerPage: commonHelper.perPageRecord,
      showValueSetModal: false,
      showOpenKmModal: false,
      editMode: false,
      subUnitMpngIndex: null,
      parent_value_set_id: null,
      leaseProject: {
        value: null,
        text: null
      },
      leaseTower: {
        value: null,
        text: null
      },
      leaseFloor: {
        value: null,
        text: null
      },
      leaseUnit: {
        value: null,
        text: null
      },
      fmsProject: {
        value: null,
        text: null
      },
      fmsTower: {
        value: null,
        text: null
      },
      fmsFloor: {
        value: null,
        text: null
      },
      fmsMasterPrj: {
        value: null,
        text: null
      },
      area: null,
      bill_area: null,
      approval_status: null,
      wfDtlId: null,
      area_uom: null,
      unit_area_uom: null,
      fms_sub_unit_name: null,
      showDownloadModal: false,
      start_date: null,
      end_date: null,
      showFmsSubUnitModal: false,
      searchFmsSubUnitName: null,
      notificationComments: null,
      unfilteredSubUnitList: [],
      downloadItems: [],
      downloadFields: [
        {
          key: 'original_file_name',
          label: 'File Name'
        },
        {
          key: 'doc_version',
          label: 'Version'
        },
        {
          key: 'file_size',
          label: 'Size'
        },
        {
          key: 'comments'
        },
        {
          key: 'created_by'
        },
        {
          key: 'creation_date'
        }
      ],
      fmsSubUnitData: [],
      fmsSubUnitFields: [
        {
          key: 'text',
          label: 'FMS Sub Unit Name'
        },
        {
          key: 'value',
          label: 'FMS Sub Unit Id'
        }
      ],
      active: false,
      fmsSubUnitList: [],
      leaseSubUnitDtlData: [
        {
          lease_sub_unit_mpng_id: 0,
          lease_prj_unit_id: 0,
          fms_sub_unit_id: 0,
          fms_sub_unit_name: null,
          party_name: null,
          customer_id: null,
          billable_area: null,
          billable_area_uom_vset: null,
          billable_area_uom_vset_meaning: null,
          period_from: null,
          period_to: null,
          active: true
        }
      ],
      leaseSubUnitDtlDataFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'lease_sub_unit_mpng_id',
          class: 'd-none'
        },
        {
          key: 'lease_prj_unit_id',
          class: 'd-none'
        },
        {
          key: 'fms_sub_unit_id',
          class: 'd-none'
        },
        {
          key: 'fms_sub_unit_name',
          label: 'FMS Sub Unit'
        },
        {
          key: 'billable_area',
          label: 'FMS Billable Area'
        },
        {
          key: 'billable_area_uom_vset',
          class: 'd-none'
        },
        {
          key: 'billable_area_uom_vset_meaning',
          label: 'FMS Billable UOM'
        },
        {
          key: 'vendor_details'
        },
        {
          key: 'vendor_count',
          label: 'Vendor Available'
        },
        {
          key: 'active',
          stickyColumn: true
        },
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        },
        {
          key: 'created_by'
        },
        {
          key: 'creation_date'
        },
        {
          key: 'last_updated_by'
        },
        {
          key: 'last_update_date'
        }
      ],
      showVendorModal: false,
      rowDetails: null
    };
  },
  mounted() {
    if (this.leasePrjUnitId) {
      this.getLeaseUnitMappingDetailsById(this.leasePrjUnitId);
    } else {
      this.editMode = true;
    }
    if (this.$route.params.summaryData) {
      this.urlSummaryData = JSON.parse(
        Buffer.from(this.$route.params.summaryData, 'base64').toString('ascii')
      );
    }
    if (this.urlSummaryData) {
      this.wfDtlId = this.urlSummaryData.wf_inprocess_dtl_id;
    } else {
      this.wfDtlId = this.wf_inprocess_dtl_id;
    }

    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'save' && this.editMode) {
          this.addLeaseSubUnitMapping();
        }
        if (actionName === 'delete' && this.editMode) {
          this.deleteMappedSubUnit();
        }
      }
    });
    if (this.urlSummaryData) {
      this.getLeaseUnitMappingDetailsById(
        this.urlSummaryData.lease_prj_unit_id
      );
      this.wfDtlId = this.urlSummaryData.wf_inprocess_dtl_id;
    } else {
      // this.nfaCreator = this.getUserName();
      this.getEventId();
    }
  },
  methods: {
    showHideVendorModal(flag, item) {
      this.showVendorModal = flag;
      this.rowDetails = item;
    },
    deleteMappedSubUnit() {
      const filterData = this.leaseSubUnitDtlData.filter(
        data => data.selectBox
      );
      const subUnitDetails = filterData.map(elem => {
        return {
          fmsSubunitId: elem.fms_sub_unit_id
        };
      });
      const payload = {
        leasePrjUnitId: this.parentLeaseUnitData.lease_prj_unit_id,
        assignSubUnits: subUnitDetails ? subUnitDetails : null
      };
      this.loader = true;
      this.$store
        .dispatch('leaseAssignSubUnit/deleteMappedSubUnit', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    getLeaseUnitMappingDetailsById(leasePrjUnitId) {
      const payload = leasePrjUnitId;
      this.loader = true;
      this.$store
        .dispatch('leaseAssignSubUnit/getLeaseSubUnitDetailsById', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            this.leaseProject = {
              value: results.lease_prj_id,
              text: results.lease_prj_name
            };
            this.leaseTower = {
              value: results.lease_tower_id,
              text: results.lease_tower_name
            };
            this.leaseFloor = {
              value: results.lease_floor_id,
              text: results.lease_floor_name
            };
            this.area = results.area_mapped;
            this.area_uom = results.fms_billable_area_uom_vset_meaning;
            this.unit_area_uom = results.billable_area_uom_vset_meaning;
            this.bill_area = results.billable_area;
            this.approval_status = results.approval_status;
            this.active = results.active;
            this.leaseUnit = {
              value: results.lease_prj_unit_id,
              text: results.unit_name
            };
            this.fmsProject = {
              value: results.fms_prj_id,
              text: results.fms_prj_name
            };
            this.fmsTower = {
              value: results.fms_tower_id,
              text: results.fms_tower_name
            };
            this.fmsFloor = {
              value: results.fms_floor_id,
              text: results.fms_floor_name
            };
            this.start_date = results.start_date;
            this.end_date = results.end_date;
            if (
              results.sub_unit_details &&
              results.sub_unit_details.length > 0
            ) {
              this.leaseSubUnitDtlData = results.sub_unit_details.map(key => {
                return {
                  lease_sub_unit_mpng_id: key.lease_sub_unit_mpng_id,
                  lease_prj_unit_id: key.lease_prj_unit_id,
                  fms_sub_unit_id: key.fms_sub_unit_id,
                  fms_sub_unit_name: key.fms_sub_unit_name,
                  customer_id: key.customer_id,
                  billable_area: key.billable_area,
                  billable_area_uom_vset: key.billable_area_uom_vset,
                  billable_area_uom_vset_meaning:
                    key.billable_area_uom_vset_meaning,
                  party_name: key.party_name,
                  active: key.active,
                  period_from_period_to: [
                    new Date(key.period_from),
                    new Date(key.period_to)
                  ]
                };
              });
              this.leaseSubUnitDtlData = results.sub_unit_details.map(item => {
                item.vendor_details = 'Details';
                return item;
              });
            }
            if (
              this.$route.name === 'notificationSummary' ||
              this.$route.name === 'dashboard' ||
              this.$route.name === 'workFlowSummary'
            ) {
              this.notificationSummary = true;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addLeaseSubUnitMapping() {
      const details = this.leaseSubUnitDtlData.map(key => {
        return {
          active: key.active,
          fms_sub_unit_id: key.fms_sub_unit_id,
          // period_from: commonHelper.formattedDate(key.period_from_period_to[0]),
          // period_to: commonHelper.formattedDate(key.period_from_period_to[1]),
          lease_sub_unit_mpg_Id: key.lease_sub_unit_mpng_id
        };
      });
      const payload = {
        lease_prj_unit_id: this.leaseUnit.value,
        subUnitMapping: {
          sub_units_details: details ? details : null
        }
      };
      this.loader = true;
      this.$store
        .dispatch('leaseAssignSubUnit/addLeaseSubUnitMapping', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.leaseSubUnitDtlData = response.data.data.sub_unit_details.map(
              detail => {
                detail.period_from_period_to = [
                  new Date(detail.period_from),
                  new Date(detail.period_to)
                ];
                return detail;
              }
            );
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    getOtherLovByProjectId() {
      this.loader = true;
      const payload = {
        lovFieldName: this.lovFieldName,
        lovId: this.lovId
      };
      this.$store
        .dispatch('receipt/getOtherLovByLovFieldAndId', payload)
        .then(response => {
          this.loader = false;
          this.fmsSubUnitData = [];
          this.unfilteredSubUnitList = [];
          if (response && response.data && response.data.data) {
            const results = response.data.data;
            if (this.lovFieldName === appStrings.FMSLOV.FMSFLOOR) {
              const floorBasedSubUnit = results.subunits.map(key => {
                return {
                  value: key.sub_unit_id,
                  text: key.sun_unit_name
                };
              });
              this.fmsSubUnitData = floorBasedSubUnit;
              this.unfilteredSubUnitList = floorBasedSubUnit;
            }
          }
        })
        .catch(error => {
          this.loader = false;
          alert(error.message);
        });
    },
    validateDoubleFmsSubUnits(item) {
      const findSubUnitExist = this.leaseSubUnitDtlData.find(key => {
        return item.value === key.fms_sub_unit_id;
      });
      return findSubUnitExist ? true : false;
    },
    selectedSubUnit(item) {
      if (this.validateDoubleFmsSubUnits(item)) {
        return alert('Existing Sub Unit cannot be added!');
      }
      this.leaseSubUnitDtlData[this.subUnitMpngIndex].fms_sub_unit_name =
        item.text;
      this.leaseSubUnitDtlData[this.subUnitMpngIndex].fms_sub_unit_id =
        item.value;
      this.showFmsSubUnitModal = false;
    },
    filteredEventList() {
      if (
        undefined === this.searchFmsSubUnitName ||
        this.searchFmsSubUnitName === ''
      )
        return this.unfilteredSubUnitList;
      return this.unfilteredSubUnitList.filter(product => {
        let flag;
        for (let prop in product) {
          flag = false;
          flag =
            product[prop].toString().indexOf(this.searchFmsSubUnitName) > -1;
          if (flag) break;
        }
        return flag;
      });
    },
    openCloseModal(flag, index) {
      this.subUnitMpngIndex = index;
      this.showFmsSubUnitModal = flag;
    },
    addNewRow() {
      if (this.editMode) {
        this.showFmsSubUnitModal = true;
      }
    },
    removeRow(index) {
      if (this.editMode) {
        this.leaseSubUnitDtlData.splice(index, 1);
      }
    },
    mapSubUnitForLease(rows) {
      this.showFmsSubUnitModal = false;
      const subUnitDetails = rows.map(key => {
        return {
          lease_sub_unit_mpng_id: 0,
          lease_prj_unit_id: this.leaseUnit.value,
          fms_sub_unit_id: key.fms_sub_unit_id,
          fms_sub_unit_name: key.fms_sub_unit_name,
          party_name: null,
          customer_id: null,
          billable_area: null,
          billable_area_uom_vset: null,
          billable_area_uom_vset_meaning: null,
          period_from_period_to: [
            new Date(this.start_date),
            new Date(this.end_date)
          ],
          period_from: this.start_date,
          period_to: this.end_date,
          active: true
        };
      });
      this.leaseSubUnitDtlData = this.leaseSubUnitDtlData.concat(
        subUnitDetails
      );
      this.leaseSubUnitDtlData = this.leaseSubUnitDtlData.filter(
        (value, index, arr) =>
          index ===
          arr.findIndex(key => key.fms_sub_unit_id === value.fms_sub_unit_id)
      );
      this.leaseSubUnitDtlData.forEach((elem, index, arr) => {
        if (!elem.fms_sub_unit_id) {
          arr.splice(index, 1);
        }
      });
    },
    checkUncheckAll() {
      this.leaseSubUnitDtlData = this.leaseSubUnitDtlData.map(data => {
        data.selectBox = this.selectAllCheckBox;
        return data;
      });
    },
    selectBoxChecked(flag, index) {
      this.leaseSubUnitDtlData[index].selectBox = flag;
    },
    setDate(dateRange, index) {
      this.leaseSubUnitDtlData[
        index
      ].period_from_period_to = commonHelper.setFutureDate(dateRange);
    },
    changeStatus(status) {
      this.loader = true;
      const payload = [
        {
          event_id: this.urlSummaryData
            ? this.urlSummaryData.wf_event_id
            : this.eventId,
          notification_comments: this.notificationComments,
          notification_status: status,
          wf_inprocess_dtl_id: this.wfDtlId,
          temp_approved_amount: 0
        }
      ];
      this.$store
        .dispatch('intWorkflow/approvalReject', { approval_requests: payload })
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            // this.getApprovalHistory(this.resHdrId);
            const result = response.data.data;
            this.editMode = false;
            this.isSuccess = true;
            result.forEach(key => {
              this.responseMsg.push(key.message);
            });
          } else {
            this.isSuccess = false;
            this.responseMsg.push(response.response.data.message);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideConsult(flag) {
      this.showConsultModal = flag;
    },
    checkOpenKmPasswordSaved() {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/checkSavedPassword')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data) {
              this.getOpenKmAuthToken();
            } else {
              this.showHideOpenKmModal(true);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getOpenKmAuthToken() {
      const payload = {
        password: null,
        saved_password_flag: true,
        user_name: null
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmAuthenticate', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openkmAuthToken = response.data.data.token;
            this.downloadFiles(true);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideOpenKmModal(flag) {
      this.showOpenKmModal = flag;
    },
    setOpenKmToken(token) {
      this.showHideOpenKmModal(false);
      this.openkmAuthToken = token;
      this.downloadFiles(true);
    },
    downloadFiles(flag) {
      this.showDownloadModal = flag;
      this.getLeaseUnitMappingDetailsById(
        this.urlSummaryData.lease_prj_unit_id
      );
    },
    getEventId() {
      this.$store
        .dispatch('intWorkflow/getEventId', store.state.shared.menuId)
        .then(response => {
          if (response.status === 200) {
            this.eventId = response.data.data;
          }
        });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
