<template>
  <div>
    <addInternalWorkflow v-if="wfType === 'nfa'" />
    <addEditMasterAgreement v-else-if="wfType === 'mla'" />
    <addEditTenantLease v-else-if="wfType === 'tla'" />
    <leaseTenantSchedule v-else-if="wfType === 'tls'" />
    <addEditInvestorLease v-else-if="wfType === 'ila'" />
    <leaseInvestorSchedule v-else-if="wfType === 'isa'" />
    <bankBatch v-else-if="wfType === 'h2h'" />
    <addEditSourcingApproval v-else-if="wfType === 'sa'" />
    <addEditMutation v-else-if="wfType === 'mutation'" />
    <uploadDoc v-else-if="wfType === 'upload-doc'" />
    <assignSubUnitDetails v-else-if="wfType === 'assignunit'" />
    <leaseUnitDetails v-else-if="wfType === 'leaseunit'" />
    <addEditPartyOnboarding v-else-if="wfType === 'vendorportal'" />
    <salesAgreement v-else-if="wfType === 'fmssaleagg'" />
    <invoiceApproval v-else-if="wfType === 'poinvoice'" />

    <!-- <invoiceEntry v-else /> -->
  </div>
</template>
<script>
import addInternalWorkflow from '../onlineApproval/internalWorkFlow/addInternalWorkflow';
import addEditMasterAgreement from '../leaseAdmin/lease/leases/masterAgreement/addEditMasterAgreement';
import addEditTenantLease from '../leaseAdmin/lease/leases/tenantLease/addEditTenantLease';
import leaseTenantSchedule from '../leaseAdmin/lease/schedules/tenantSchedule/tenantDetails';
import addEditInvestorLease from '../leaseAdmin/lease/leases/investorLease/addEditInvestorLease';
import leaseInvestorSchedule from '../leaseAdmin/lease/schedules/investorSchedule/investorDetails';
import bankBatch from '../payable/payments/hostToHostBankTransfer/payments/paymentDetails';
import addEditSourcingApproval from '../onlineApproval/nfaApproval/addNfaApproval';
import addEditMutation from '../fms/mutation/addEditMutation';
import uploadDoc from '../shared/commonUploadDoc';
import assignSubUnitDetails from '../leaseAdmin/lease/masterLease/leaseAssignSubUnit/assignSubUnitDetails';
import addEditPartyOnboarding from '../generalLedger/partyOnboarding/partyDetails';
import leaseUnitDetails from '../leaseAdmin/lease/masterLease/leaseUnit/leaseUnitDetails';
import salesAgreement from '../fms/salesAgreement/createSalesAgreement';
import invoiceApproval from '../generalLedger/invoiceApproval/invoiceApprovalDetails';
// import invoiceEntry from '../payable/apInvoice/invoiceEntry/viewInvoiceEntry'
export default {
  name: 'wfSummary',
  components: {
    addInternalWorkflow,
    addEditMasterAgreement,
    addEditTenantLease,
    addEditInvestorLease,
    leaseTenantSchedule,
    leaseInvestorSchedule,
    bankBatch,
    addEditSourcingApproval,
    addEditMutation,
    uploadDoc,
    assignSubUnitDetails,
    addEditPartyOnboarding,
    leaseUnitDetails,
    salesAgreement,
    invoiceApproval
    // invoiceEntry
  },
  data() {
    return {
      wfType: null
    };
  },
  mounted() {
    if (this.$route.params.wfType) {
      this.wfType = this.$route.params.wfType;
    }
  }
};
</script>
<style>
.wf-scroll {
  background-color: white;
  overflow: auto;
  height: calc(100vh - 25px) !important;
  margin: 5px 10px 5px 10px;
}
</style>
