export default {
  name: 'vendorDetails',
  components: {},
  props: ['rowDetails'],
  data() {
    return {
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      vendorData: [],
      vendorFields: [
        {
          key: 'vendor_name'
        },
        {
          key: 'vendor_site_name'
        },
        {
          key: 'share_ratio'
        },
        {
          key: 'billable_area_sharing_ratio',
          label:  'Billable Area'
        }
      ]
    };
  },
  mounted() {
    this.getVendorDetailsByUnit(this.rowDetails.fms_sub_unit_id);
  },
  methods: {
    getVendorDetailsByUnit(unitId) {
      this.loader = true;
      this.$store
        .dispatch('leaseAssignSubUnit/getVendorDetailsById', unitId)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.vendorData = resp.data.data;
          }
        });
    }
  }
};
